import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Aquarius.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
              Aquarius 
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/aquarius"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Aquarius </h4>
              </a>
              
              <h4 className="mx-2 text-sm"></h4>
              <h4 className="text-sm"></h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Aquarius </h1>
                <h2 className="text-sm md:text-base ml-4">Jan 20 - Feb 18</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aquarius Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aquarius Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aquarius Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-love");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-nature");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-man");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-traits");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-facts");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

             
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="font-bold text-orange-500 inline-block mr-2">anskrit/Vedic name of Aquarius:</p> Kumbha<br/><br/>
            <p className="font-bold text-orange-500 inline-block mr-2">Aquarius Meaning:</p> The Water-bearer<br/><br/>
            <p className="font-bold text-orange-500 inline-block mr-2">Type:</p>: Air-Fixed-Positive<br/><br/>
            <p className="font-bold text-orange-500 inline-block mr-2">Exaltation:</p> The Sun <br/><br/>
            <p className="font-bold text-orange-500 inline-block mr-2">Fal for l:</p> The Sun <br/><br/>
            <p className="playfair text-black text-xl md:text-3xl">Aquarius Sign</p><br/>
Aquarius is the 11th Sign of the Zodiac. A sign of all that’s humane and humanity, this Sign is stylish, modern, self-dependent, and freedom-loving. While everything fresh and new-age fascinates the Aquarius natives, disobedience of any sort liberates them. 
 <br/><br/>
Aquarius is the Sign of discoverers, innovators, creators, adventurers, and visionaries. Friendly and upbeat by disposition, Aquarius-born tend to have an abundance of colonial charm. Yet, they lack the depth required to thrash and facilitate genuinely profound relationships. 
<br/><br/>
Aquarians are passionate and committed explorers, explorers who have the desire to live by a dream. They are the explorers of the future.<br/><br/>
They are the ideal Sign for those hoping to live a life of purpose and action and see themselves as creative individuals.
<br/><br/>
In the seventh house, the rulers of Aquarius are often known for their fondness for travel, exploration, new ideas, and self-discovery. The most significant aspect of a person in Aquarius is their creativity. Aquarius people are not limited to a particular field of study or expertise. Aquarius people can take the world and make it their own.

<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">More about Aquarius</p>
<br/>
That is why the Aquarius natives may have a lot of understanding but very few close friends. Nonetheless, their colonial charm is non-negotiable, and they are often perceived as pretty appealing by the opposite sex. But, similarly, in love, the Aquarius may never feel as enthralled as a Pisces will – for to them (Aquarius), it’s the whole comprehensive world that’s crucial and vital. Most of them, thus, have a mission or a more prominent cause in their sense. 
<br/><br/>
Even if some Aquarius seemed to lead utterly mundane energy, you would find that they have somehow attached themselves to a charitable cause on some digging. However, gracious and merciful, the Aquarius is very different from Pisces or Cancer – the other two Signs are perceived as very kind. Aquarius is more practical and has a keen eye, quickly seeing through untruthfulness and backstabbing. Nonetheless, as parents, spouses, or friends, Aquarius-born is validating, optimistic, and encouraging; they are often ‘the go-to frontier’ for numerous (Aquarius) who will consistently be ready to help with honest, impartial guidance and practically contagious optimism.<br></br>
<br></br>
<p className="playfair text-black text-xl md:text-3xl">All About Aquarius</p><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Ruling planets:</p> Saturn and Uranus<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Element Air</p><br/><br/>
QualityFixed (= stability)<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Symbol:</p> A man emptying/ carrying a water pot/ The Water Bearer.<br/><br/>
The Symbol denotes water of life and the benevolence of freshwater to nurture humanity on earth.
<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Characteristics:</p> Masculine, Humane, Scientific, Eloquent, Instinctive<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Noteworthy qualities:</p> Leadership skillfulness, honesty, scientific, sincerity, earnestness, compassion, cooperation, equitable, innovative, intellectual, intuitive, pleasant, considerate, and progressive.
<br/><br/>
Undesirable personality traits Gullible, extremist, irresponsible, withdrawn, cold, stubborn, unreliable, aloof.<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Birthstone:</p> Amethyst and Blue Sapphire (The gemstone is suggested considering Aries as the Ascendant/Lagna Sign. To know your Ascendant/Lagna Sign, click here.)<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Birth Colours:</p> Electric Blue, Grey, Ultramarine Blue<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Other lucky colors:</p> Aqua Blue, Navy Blues<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Lucky Numbers:</p> 4, 8, 13, 17, 22, 26, 31, 35<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Compatible Signs:</p> Gemini, Libra<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Lucky Day:</p> Saturday<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Most profound need:</p> To know and to bring in the new; To experiment, improvise and innovate<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Quality most needed for:</p> balance warmth, Feeling, and Sentiment<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Detriment: </p> The Sun<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Exaltation:</p> Neptune<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Fall:</p> Pluto<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Associated Flowers and Plants:</p> Orchids, Fruit Trees<br/><br/>
Best Sign/s for marriage and/or partnerships Leo<br/><br/>
Anatomically Aquarius compares to The coccyx at the base of the spine, the calves, and ankles, the cones and rods in the eye; lower leg bones, coccyx, and shins; muscles in the calves, ankles, and shins; arteries in the lower leg; veins in the lower leg.
<br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
